import React from 'react'
import Numeral from 'numeral'
import _replace from 'lodash/replace'
import _split from 'lodash/split'
import _toNumber from 'lodash/toNumber'
import _compact from 'lodash/compact'
import _toLower from 'lodash/toLower'
import _reduce from 'lodash/reduce'
import _keys from 'lodash/keys'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'
import _trim from 'lodash/trim'
import _words from 'lodash/words'
import _truncate from 'lodash/truncate'

import {
  ImageSetType,
  SpaceImageRequest,
  OriginImageRequest,
  EngineConfigData,
  Post,
  SketchGenre,
  Clip,
  ProArtFilterGenre
} from 'models/ApiModels'

import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import isToday from 'dayjs/plugin/isToday'
import { link, values } from 'appConstants'
import { FirebaseError } from 'firebase/app'
import { route } from 'routes'

dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.extend(isToday)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '%dS',
    m: '1MIN',
    mm: '%dMINS',
    h: '1H',
    hh: '%dH',
    d: '1D',
    dd: '%dD',
    M: '1MON',
    MM: '%dMON',
    y: '1Y',
    yy: '%dY'
  }
})

const SCOPE_SEPARATOR = '_'
const ITERATION_PREFIX = 'ss'
export const TRAINING_TEXT = 'training'
export const LATENT_TEXT = 'latent'
export const SKETCH_TEXT = 'skt'
export const TEXT_TO_IMAGE_TEXT = 't2i'
export const STYLIZE_TEXT = 'st'
export const GENERICAPP_TEXT = 'app'

const BookmarkUtils = {
  adjustBookmarkScope: (scope: string = '') => {
    /*
    - In backend, the bookmark scope in TrainingProject will changed into training_{id}_ss_{num}
    - Project will have an extra field snapshot showing current number
    - When query for bookmark, you query for training_{id} , and it will return all bookmark 
      images startswtih training_id , include those training_{id}_ss_{num}
    - You can compare data to decide disable some bookmark or not.
    - I will do migration for existing bookmark, make them all under latest snapshot.

      So only take first 2 splited parameter
    */

    const isTrainingScope = scope.startsWith(TRAINING_TEXT)

    if (isTrainingScope) {
      const scopeSplited = scope.split(SCOPE_SEPARATOR)
      return `${scopeSplited[0]}${SCOPE_SEPARATOR}${scopeSplited[1] ?? ''}`
    } else {
      return scope
    }
  },
  getTrainProjectScopeIteration: (scope: string) => {
    const thirdIteration = scope.split(SCOPE_SEPARATOR)[2] ?? ''
    return _toNumber(thirdIteration.replace(ITERATION_PREFIX, ''))
  },
  splitBookmarkScope: (scope: string) => {
    if (scope.startsWith(GENERICAPP_TEXT)) {
      const id = scope.replace(GENERICAPP_TEXT, '')

      return {
        id: _toNumber(id),
        scope: GENERICAPP_TEXT
      }
    }
    if (scope.startsWith(SKETCH_TEXT)) {
      const id = scope.replace(SKETCH_TEXT, '')

      return {
        id: _toNumber(id),
        scope: SKETCH_TEXT
      }
    }
    if (scope.startsWith(STYLIZE_TEXT)) {
      const id = scope.replace(STYLIZE_TEXT, '')

      return {
        id: _toNumber(id),
        scope: STYLIZE_TEXT
      }
    }
    if (scope.startsWith(TEXT_TO_IMAGE_TEXT)) {
      const id = scope.replace(TEXT_TO_IMAGE_TEXT, '')

      return {
        id: _toNumber(id),
        scope: STYLIZE_TEXT
      }
    } else {
      const scopeSplited = scope.split(SCOPE_SEPARATOR)

      return {
        id: _toNumber(scopeSplited[1]),
        scope: scopeSplited[0]
      }
    }
  },
  getIsNotLatestIteration: (scope: string, currentSnapshot: number) => {
    const imageIteration = BookmarkUtils.getTrainProjectScopeIteration(scope)

    return imageIteration !== currentSnapshot - 1
  }
}

export const SocialUtils = {
  getCategoryName: (
    engineConfigData?: EngineConfigData,
    postData?: Post,
    sketchGenreData?: { [id: number]: SketchGenre },
    styleTranferGenre?: { [id: number]: ProArtFilterGenre }
  ) => {
    const projectCategory = postData?.related?.category || ''
    const object_type = postData?.related?.object_type
    const genre = postData?.related?.genre ?? -1

    if (object_type === 'sketch_project') {
      const genreText = sketchGenreData?.[genre]?.name ?? ''
      return `Sketch To Image${genreText ? ' - ' : ' '}${genreText}`
    } else if (object_type === 'pretrain_mix_project') {
      return 'Faces'
    } else if (object_type === 'training_mix_project') {
      return 'Training Mix'
    } else if (object_type === 't2i_project') {
      return 'Text To Art'
    } else if (object_type === 't2v_project') {
      return 'Text To Video'
    } else if (object_type === 'transfer_project') {
      const genreText = styleTranferGenre?.[genre]?.name ?? ''
      return `Stylize Filter${genreText ? ' - ' : ' '}${genreText}`
    } else if (object_type === 'app_project') {
      return 'AI App'
    } else if (object_type === 'ST2I_project') {
      return 'Prompt and Sketch'
    } else {
      return engineConfigData ? engineConfigData?.[projectCategory]?.name : ''
    }
  }
}

const TimeFieldUtils = {
  getText: (value: number) => (value >= 10 ? `${value}` : `0${value}`),

  secondToText: (value: number) => {
    const minute = Math.floor(value / 60)
    const second = Math.floor(value) % 60
    const fractionSecond = value - Math.floor(value)
    const fractionSecondAdjusted = Math.round(fractionSecond / (1 / 30))

    const textMinute = TimeFieldUtils.getText(minute)
    const textSecond = TimeFieldUtils.getText(second)
    const textFractionSecond = TimeFieldUtils.getText(fractionSecondAdjusted)

    return `${textMinute}:${textSecond}:${textFractionSecond}`
  },

  textToSecond: (text: string) => {
    const [minute, second, fractionSecond] = text.split(':')

    const minuteNumber = _toNumber(minute)
    const secondNumber = _toNumber(second)
    const fractionSecondNumber = _toNumber(fractionSecond)
    const fractionSecondNumberAdjusted = fractionSecondNumber * (1 / 30)

    return minuteNumber * 60 + secondNumber + fractionSecondNumberAdjusted
  },

  isValid: (text: string, min: number = 0, max: number = Infinity) => {
    const [, , fractionSecond] = text.split(':')
    const fractionSecondNumber = _toNumber(fractionSecond)
    const valueSecond = TimeFieldUtils.textToSecond(text)

    return fractionSecondNumber < 30 && valueSecond <= max && valueSecond >= min
  }
}

const Format = {
  number: (number: number, format: string = '0,0') => {
    return Numeral(number).format(format)
  },
  numberWithDecimalTrail: (number: number, decimal: number) => {
    let decimalText = ''

    for (let i = 0; i < decimal; i++) {
      if (i === 0) {
        decimalText = '.'
      }
      decimalText = `${decimalText}0`
    }

    return Numeral(number).format(`0,0${decimalText}`)
  },
  commarizeNumber: (number: number) => {
    return number > 1000 ? Numeral(number).format('0.0a') : number
  },
  currency: (number: number = 0, currencyCode: string = '', zeroCount: number = 0) => {
    let currencySymbol = ''
    let zeroCountText = ''

    switch (currencyCode.toUpperCase()) {
      case 'USD':
        currencySymbol = '$'
        break
      default:
        break
    }

    for (let i = 0; i < zeroCount; i++) {
      if (i === 0) {
        zeroCountText = '[.]'
      }
      zeroCountText = `${zeroCountText}0`
    }

    return Numeral(number).format(`${currencySymbol}0,0${zeroCountText}`)
  },
  creditAmount: (number: number) => {
    return Numeral(number).format('0,0.[0]')
  },
  /*
    Format date and ignore displaying year if same with the current year. 
  */

  formatDate: (dateString: string) => {
    if (!dateString) {
      return ''
    }

    const date = dayjs(dateString)

    if (date.isToday()) {
      return date.format(values.TIMESTAMP_FORMAT_TIME_ONLY)
    }

    const currentYear = dayjs().year()

    return date.year() === currentYear
      ? date.format(values.TIMESTAMP_FORMAT_NO_YEAR)
      : date.format(values.TIMESTAMP_FORMAT)
  }
}

interface Name {
  first_name?: string
  last_name?: string
}

export const NameUtils = {
  splitName: (name: string): Name => {
    const [firstName, ...lastNames] = _words(name)

    return {
      first_name: firstName,
      last_name: _trim(
        lastNames.reduce((result, lastName) => {
          result = `${result} ${lastName}`
          return result
        }, '')
      )
    }
  },
  getSlicedName: (name?: Name, limit: number = 10) => {
    const first_name = name?.first_name ?? ''
    const last_name = name?.last_name ?? ''
    const nameLength = first_name.length + last_name.length

    if (nameLength > limit) {
      const [firstName, ...restFirstNames] = _words(first_name)

      const firstNameTruncated = _truncate(firstName, { length: 20, omission: '' })
      const restFirstNamesCombined = _trim(
        restFirstNames.reduce((result, lastName) => {
          result = `${result} ${lastName}`
          return result
        }, '')
      )

      const lastNameInitial = _words(`${restFirstNamesCombined} ${last_name}`)
        .reduce((result, lastName) => {
          result = `${result}${lastName?.[0] ?? ''}`
          return result
        }, '')
        .toUpperCase()

      return `${firstNameTruncated} ${lastNameInitial}`
    } else {
      return `${first_name} ${last_name ?? ''}`
    }
  }
}

const ActionKeyCreator = {
  generate: (data: any) => {
    if (!data || typeof data !== 'object') {
      return ''
    }
    return window.btoa(JSON.stringify(data))
  },
  parse: (key: string) => {
    try {
      const data = window.atob(key)
      return JSON.parse(data)
    } catch (e) {
      return ''
    }
  }
}

/*


/* Convert Object to search param and back */
const UrlUtils = {
  objectToParam: <InputType extends { [key: string]: any }>(input: InputType) => {
    return Object.keys(input)
      .map(function (key: string) {
        return key + '=' + input[key]
      })
      .join('&')
  },
  paramToObject: (input: string, data: Array<string> = []) => {
    const result: { [key: string]: string } = {}
    const inputAdjusted = input[0] === '?' ? input.substring(1) : input
    try {
      const parsed = JSON.parse(
        '{"' +
          decodeURI(inputAdjusted).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}'
      )
      return data.length
        ? _reduce(
            data,
            (result, key) => {
              result[key] = parsed[key] || ''
              return result
            },
            result
          )
        : parsed
    } catch (error) {
      return data.length
        ? _reduce(
            data,
            (result, key) => {
              result[key] = ''
              return result
            },
            result
          )
        : {}
    }
  },
  updateUrlParam: <InputType extends { [key: string]: any }>(
    currentUrl: string,
    data: InputType
  ) => {
    const inputData = UrlUtils.paramToObject(currentUrl)

    _keys(data).forEach(key => (inputData[key] = data[key]))

    const cleanedInputData = _omitBy(inputData, _isNil)

    return UrlUtils.objectToParam(cleanedInputData)
  },
  replaceState: (url: string) => {
    window.history.replaceState(null, '', url)
  },
  pushState: (url: string) => {
    window.history.pushState(null, '', url)
  },
  /*
   *  Get file extension from AWS URL
   * "https://s3.ap-southeast-1.amazonaws.com/testing.playform.private/media/output-images-20190804/afc8aaad5402493f88192f4109e10d4f.jpeg?AWSAccessKeyId=AKIAJSCA55KQ24I3NPTQ&Signature=BTX1PYSM9e3AKDpAhX2YT2Bwga8%3D&Expires=1565966927"
   */

  getFileTypeFromUrl: (url: string = '') => {
    const step1 = _split(url, '/')
    const step2 = step1[step1.length - 1]
    const step3 = _split(step2, '?')[0]
    const step4 = _split(step3, '.')[1]

    return step4 || 'jpeg'
  },

  getFileNameFromUrl: (url: string = '') => {
    const step1 = _split(url, '/')
    const step2 = step1[step1.length - 1]
    const step3 = _split(step2, '?')[0]
    const step4 = _split(step3, '.')[0]

    return step4 || ''
  },
  getActionParam: (search: string, param?: string) => {
    const params = new URLSearchParams(search)

    const action = params.get(param || 'action')

    return action ? ActionKeyCreator.parse(action) : {}
  },
  getParam: (search: string, param?: string) => {
    const params = new URLSearchParams(search)

    return params.get(param ?? '')
  },
  getUserProfileUrl: (user?: Post['user']) => {
    return user?.alias ? route.ARTISTS.getUrl({ artistUrl: user?.alias }) : undefined
  }
}

const GetText = {
  fileSize: (value?: number) => {
    if ((value ?? 0) < 1024) {
      return `${value} MB`
    } else {
      return `${Math.round((value ?? 0) / 10.24) / 100} GB`
    }
  },
  plus: (hide?: boolean) => `${!hide ? '(PLUS)' : ''}`,
  pro: (hide?: boolean) => `${!hide ? '(PRO)' : ''}`,
  originImageKey: ({ pos_x, pos_y }: OriginImageRequest) => `${pos_x}-${pos_y}`,
  spaceImageNaturalKey: ({ parent_image_id, pos_x, pos_y }: SpaceImageRequest) => {
    if (!parent_image_id) {
      return '0-0'
    } else {
      return `${parent_image_id}-${pos_x}-${pos_y}`
    }
  },
  clipListKey: ({ id, type }: Partial<Clip['project']>) => (id && type ? `${id}-${type}` : ''),
  artmineUrl: (projectId?: number) =>
    `${link.ARTMINE_URL}${projectId ? `projects/${projectId}` : ''}`,
  /*
   * Get Relative time
   */
  relativeTime: (date: Date | Dayjs | string) => {
    return dayjs(date).fromNow()
  },
  /*
   * Return proper text for inspiration and aesthetics
   */

  inputProjectText: (imageSet: ImageSetType | undefined, projectCategory?: string) => {
    if (imageSet === 'inspiration') {
      return 'Inspiration'
    } else if (imageSet === 'aesthetic') {
      if (projectCategory === 'style_transfer') {
        return 'Style'
      } else {
        return 'Influence'
      }
    }
    return ''
  },
  inactiveSubscriptionText: (frozen_balance?: number): string[] => {
    return frozen_balance
      ? [
          `We were unable to renew your subscription and currently is inactive. You currently have ${frozen_balance} subscription credits that are inactive due to missed renewal.`,
          `To resume your subscription benefits please make sure your payment method is updated.`
        ]
      : [
          `We were unable to renew your subscription and currently is inactive. 
         To resume your subscription benefits please make sure your payment method is updated.`
        ]
  }
}

export type TextCaseProps = {
  str?: string | React.ReactNode | null | undefined
  format?: 'capitalize' | 'lowercase' | 'titlecase' | 'uppercase'
}

const TextTransform = {
  textCase: (str?: string | null, format?: TextCaseProps['format']) => {
    if (!str) return undefined

    if (format === 'titlecase') {
      const lower = _toLower(str)
      return lower.replace(/\w\S*/g, txt => `${txt.charAt(0).toUpperCase()}${txt.substr(1)}`) ?? ''
    } else if (format === 'uppercase') {
      return str.toUpperCase()
    }

    return str
  },
  trimAddress: (address?: string) =>
    address
      ? `${address.substring(0, 7)}...${address.substring(address.length - 4, address.length)}`
      : '',
  codeNameToTitle: (codename: string) =>
    TextTransform.textCase(codename.replaceAll('_', ' '), 'titlecase'),
  maskNumber: (value: string = '') => {
    const valueShowed = value.slice(-4)
    let countNum = ''

    for (let i = value.length - 4; i > 0; i--) {
      countNum += '*'
    }

    return countNum + valueShowed
  },

  stripHtml: (input: string = '') => {
    return input.replace(/<[^>]*>?/gm, '')
  },
  replaceAll: (string = '', search = '', replacement = '') => {
    return string.split(search).join(replacement)
  },
  /*
   *  Convert error code from firebase to dialog title
   *  auth/invalid-action-code to Invalid Action Code
   */

  firebaseErrorCodeToTitle: (error?: FirebaseError) =>
    TextTransform.replaceAll(_replace(error?.code || '', 'auth/', ''), '-', ' ').toUpperCase(),

  /* * Convert text into array, separate by new line
   */

  spreadTextToArray: (text: string) => {
    const textArray = text.split(/\r|\n/)

    //clean item that only have whitespace
    const trimmedTextArray = textArray.map(item => {
      if (item.trim().length) {
        return item
      } else {
        return ''
      }
    })

    return _compact(trimmedTextArray)
  },
  /*
   *  Create Constant for redux
   */
  constCreatorMaker: (prefix: string) => {
    function creator(name: string) {
      return `${prefix}/${name}`
    }
    return creator
  }
}

export { BookmarkUtils, TimeFieldUtils, GetText, UrlUtils, TextTransform, Format, ActionKeyCreator }
